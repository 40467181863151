import React, {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useAuth} from "../core/action";
import {Button, Col, Container, Row, Spinner} from "react-bootstrap";

const VerifyAuth = () => {
    const loc = useLocation();
    const param = new URLSearchParams(loc.search);
    const code = param.get("code");
    const state = param.get("state");
    const {exchangeAccessToken, auth: {user,accessToken}, loading, message} = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (code) {
            exchangeAccessToken(code);
        } else {
            navigate("/");
        }
        // eslint-disable-next-line
    }, [code]);

    useEffect(() => {
        if (user) {
            if (state === "isNewAuth") {
                navigate("/product-suite/add-product");
            } else {
                navigate("/");
            }
        }
    }, [user, navigate, accessToken]);

    return (
        <Container>
            <Row className="vh-100 justify-content-center align-items-center g-0">
                <Col xs={12} md={6} lg={4}>
                    <div className="text-center">
                        {loading && (
                            <span>
                                <Spinner variant="main" animation="border"/>
                                <p className="text-main mt-2">Verifying...</p>
                            </span>
                        )}
                        {message !== "" && (
                            <>
                                <p className="text-danger text-capitalize">{message}!</p>
                                <Row className="g-3">
                                    <Col xs={6}>
                                        <Button onClick={() => navigate("/")} className="w-100"
                                                variant="light">Back</Button>
                                    </Col>
                                    <Col xs={6}>
                                        <Button onClick={() => window.location.reload()} className="w-100"
                                                variant="main">Re-verify</Button>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default VerifyAuth