import {AuthModel, UserModel} from './_models'
import Cookies from "js-cookie";
import {getCookie, removeCookie, setCookie} from "../../../../_kiloit/helpers/cookie/cookieHelper";

const AUTH_LOCAL_STORAGE_KEY = 'auth-kiloit'
const USER_LOCAL_STORAGE_KEY = "current-user";
const MERCHANT_LOCAL_STORAGE_KEY = "merchant";
const APP_LOCAL_STORAGE_KEY = "app-id";
const getAuth = (): AuthModel | undefined => {

    if (!Cookies) {
        return
    }

    const lsValue: string | undefined = getCookie(AUTH_LOCAL_STORAGE_KEY);
    if (!lsValue) {
        return
    }

    try {
        const auth: AuthModel = JSON.parse(lsValue) as AuthModel
        if (auth) {
            // You can easily check auth_token expiration also
            return auth
        }
    } catch (error) {
        console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
    }
}

const setAuth = (auth: AuthModel) => {
    if (!Cookies) {
        return
    }

    try {
        const lsValue = JSON.stringify(auth)
        setCookie(AUTH_LOCAL_STORAGE_KEY, lsValue)
    } catch (error) {
        console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
    }
}

const setCurrentUser = (user: UserModel) => {
    setCookie(USER_LOCAL_STORAGE_KEY, JSON.stringify(user));
}

const getCurrentUser = (): UserModel | null => {
    const currentUser = getCookie(USER_LOCAL_STORAGE_KEY);
    if (!currentUser) {
        return null;
    }
    return JSON.parse(currentUser);
}

const removeAuth = () => {
    if (!localStorage) {
        return
    }

    try {
        removeCookie(AUTH_LOCAL_STORAGE_KEY)
        removeCookie(USER_LOCAL_STORAGE_KEY)
        removeCookie(MERCHANT_LOCAL_STORAGE_KEY)
        removeCookie(APP_LOCAL_STORAGE_KEY)
    } catch (error) {
        console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
    }
}

const parseToken = () => {
    const token = getAuth()?.accessToken;
    if (!token) return null;
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
};

const getUserId = () => {
    return parseToken().id;
};

const getPermissions = () => {
    return parseToken().permissions;
};

const getUserCode = () => {
    return parseToken().code;
};

const getUser = () => {
    return parseToken();
};

const getRole = (isSub = false) => {
    let user = getUser();
    if (isSub && user.roleCode === "sub-account") return user.parentRoleCode;
    return user?.roleCode;
};

const getSessionStorage = (key: string) => {
    return sessionStorage.getItem(key);
}

export {
    getAuth,
    setAuth,
    setCurrentUser,
    getCurrentUser,
    removeAuth,
    AUTH_LOCAL_STORAGE_KEY,
    USER_LOCAL_STORAGE_KEY,
    getUserId,
    getPermissions,
    getUserCode,
    getRole,
    getUser,
    parseToken,
    getSessionStorage
}
