const app: {[key: string]: {name: string; feature: string}} = {
    1: {
        name: "KiloFOOD",
        feature: "foods"
    },
    2: {
        name: "KiloSTAY",
        feature: "Hotel amenitie"
    },
    3: {
        name: "KiloHEALTH",
        feature: "business"
    },
    4: {
        name: "KiloHR",
        feature: "business"
    },
    5: {
        name: "KiloATTRACTION",
        feature: "business"
    },
    6: {
        name: "KiloMALL",
        feature: "business"
    }
}

export {app}