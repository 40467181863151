import React, {Fragment, useState} from 'react'
import {Col, Container, Form, Row} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import CustomBtn from 'src/app/modules/widgets/CustomBtn'
import {useBusiness} from "../../../../../../core/action";

const ConfirmationPart = () => {
    const [checked, setChecked] = useState<boolean>(false)
    const navigate = useNavigate()
    const {business, createMerchant, loading} = useBusiness();

    const handleCheck = () => {
        setChecked(!checked)
    }

    const next = () => {
        const {businessName, location, businessCategories, ...obj} = business;
        const businessIds = businessCategories.map((category) => {
            return category.value;
        })
        const payload = {...obj, phoneNumber: obj.phoneNumber.slice(1), latitude: location?.lat, longitude: location?.lng, businessIds, banners: obj.banners};
        return createMerchant(payload);
    }

    const back = () => {
        navigate("/product-suite/address-and-banner")
    }
    return (
        <Fragment>
         <Container>
            <Row className="justify-content-md-center justify-content-lg-start ">
                <Col  className='p-0 ps-md-5 ps-lg-0'>
                    <Form className='mb-5'>
                        <Form.Check
                            onChange={handleCheck}
                            className="custom-checkbox"
                            color='black'
                            type="checkbox"
                            id="default-checkbox"
                            label="By clicking the checkbox, you agree that all the above information is correct."
                        />
                    </Form>
                </Col>
            </Row>
        </Container>
            <Row className='mb-5 d-flex justify-content-md-center px-md-5 px-lg-0'>
                <Col xs={6} >
                    <CustomBtn btntype='back' func={back} />
                </Col>
                <Col xs={6} >
                    <CustomBtn reqLoading={loading} func={next} title='Next' disable={!checked} />
                </Col>
            </Row>
        </Fragment>
    )
}

export default ConfirmationPart