import Cookies from "js-cookie";

const DOMAIN = ".kiloit.com";

const cookieOption = () => {
    if (window.location.hostname === "dev-board.kiloit.com") {
        return {domain: DOMAIN, path: "/"}
    }

    return {path: "/"}
}

const setCookie = (name: string, value: string) => Cookies.set(name, value, cookieOption());
const getCookie = (name: string) => Cookies.get(name);
const removeCookie = (name: string) => Cookies.remove(name, cookieOption());

export {setCookie, getCookie, removeCookie}