import React, {FC} from "react";
import {Image} from "react-bootstrap";
import {useAuth} from "../../auth/core/action";

interface Props {
    id?: number;
    name: string;
    icon: string;
}

const AppCard: FC<Props> = ({name, icon}) => {
    const {redirectToProduct} = useAuth();

    return (
        <React.Fragment>
            <div className="cursor-pointer text-center py-4 px-2 rounded-4 app-board-wrapper"
                 onClick={() => redirectToProduct(name)}>
                <div className="app-board-card d-block mx-auto">
                    <Image className="w-100" src={icon}/>
                </div>
                <p className="mb-0 mt-2 text-truncate">{name}</p>
            </div>
        </React.Fragment>
    )
}

export default AppCard