import {authEndpoint} from "./endpoint";
import {authApi, instanceApi} from "../../../../_kiloit/helpers/axiosHelper";

export const reqGetUserByAccessToken = (): any => {
    return instanceApi.get(authEndpoint.getUserByAccessToken).then(d => d.data.data);
}
export const reqGetAllMerchants = () => {
    return instanceApi.get(authEndpoint.getAllMerchants);
}

export const reqExchangeAccessToken = (code: string) => {
    const payload = {
        grant_type: "authorization_code",
        code,
        redirect_uri: window.location.origin + "/auth/verify"
    }

    return authApi.post(authEndpoint.exchangeAccessToken, payload, {
        headers: {
            "Authorization": `Basic ${process.env.REACT_APP_BASIC_TOKEN}`,
            "Content-Type": "application/x-www-form-urlencoded",
        }
    });
}

export const reqExchangeRefreshToken = (refresh_token: string) => {
    const payload = {
        grant_type: "refresh_token",
        refresh_token
    }

    return authApi.post(authEndpoint.exchangeAccessToken, payload, {
        headers: {
            "Authorization": `Basic ${process.env.REACT_APP_BASIC_TOKEN}`,
            "Content-Type": "application/x-www-form-urlencoded",
        }
    });
}

export const reqSwitchMerchant = (payload: any) => {
    return instanceApi.post(authEndpoint.switchMerchant, payload);
}

export const reqLogout = (payload: string) => {
    return authApi.post(authEndpoint.logout, {token: payload}, {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    });
}