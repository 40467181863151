import {Dispatch, SetStateAction} from 'react'

export type ID = undefined | null | number

export type PaginationState = {
    page: number
    size: 0 | 20 | 30 | 50 | 100
    totals?: number
    totalPage?: number
}

export const initialQueryDefaultState: PaginationState = {
    page: 0,
    size: 0,
    totalPage: 0,
}

export type SortState = {
    sort?: string
    order?: 'asc' | 'desc'
}

export type FilterState = {
    filter?: unknown
}

export type SearchState = {
    search?: string
}

export type Response<T> = {
    data?: T
    payload?: {
        message?: string
        errors?: {
            [key: string]: Array<string>
        }
        pagination?: PaginationState
    }
}

export type QueryState = PaginationState & SortState & FilterState & SearchState

export type QueryRequestContextProps = {
    state: QueryState
    updateState: (updates: Partial<QueryState>) => void
}

export const initialQueryState: QueryState = {
    page: 1,
    size: 20,
}

export const initialQueryRequest: QueryRequestContextProps = {
    state: initialQueryState,
    updateState: () => {
    },
}

export type QueryResponseContextProps<T> = {
    response?: Response<Array<T>> | undefined
    refetch: () => void
    isLoading: boolean
    query: string
}

export const initialQueryResponse = {
    refetch: () => {
    }, isLoading: false, query: ''
}

export type ListViewContextProps = {
    selected: Array<ID>
    selectedItem?: Array<any>
    onSelect: (selectedId: ID, item?: any) => void
    onSelectAll: () => void
    clearSelected: () => void
    clearSelectedItem?: () => void
    // NULL => (CREATION MODE) | MODAL IS OPENED
    // NUMBER => (EDIT MODE) | MODAL IS OPENED
    // UNDEFINED => MODAL IS CLOSED
    setObject?: Dispatch<SetStateAction<any>>
    setSubAccount?: Dispatch<SetStateAction<any>>
    setDepositWithdraw?: Dispatch<SetStateAction<any>>
    setObjPermissionSubAccount?: Dispatch<SetStateAction<any>>
    setChangePassword?: Dispatch<SetStateAction<any>>
    setCloneUser?: Dispatch<SetStateAction<any>>
    object?: any,
    subAccount?: any,
    objPermissionSubAccount?: any,
    objChangePassword?: any,
    depositWithdraw?: any,
    cloneUser?: any,
    itemIdForUpdate?: ID
    setItemIdForUpdate: Dispatch<SetStateAction<ID>>
    isAllSelected: boolean
    disabled: boolean
}

export const initialListView: ListViewContextProps = {
    selected: [],
    selectedItem: [],
    onSelect: () => {
    },
    onSelectAll: () => {
    },
    clearSelected: () => {
    },
    clearSelectedItem: () => {
    },
    setItemIdForUpdate: () => {
    },
    setObject: () => {
    },
    setDepositWithdraw: () => {
    },
    setSubAccount: () => {
    },
    setObjPermissionSubAccount: () => {
    },
    setChangePassword: () => {
    },
    setCloneUser: () => {
    },
    isAllSelected: false,
    disabled: false,
}

export type subItem = {
    id: string
    title?: string
    type?: string
    url: string
    icon?: string
    roles?: any
    permissions?: any
}

export type itemModel = {
    id: string
    title: string
    type: string
    url: string
    roles?: any
    permissions?: any
    icon?: string
    isShow?: string
    children?: Array<subItem>
}

export type Menus = {
    items: Array<itemModel>
}

export type MerchantModel = {
    merchantId: ID
    merchantName: string
    roleCode: string
    userType: string
    merchantType: string
    isActive: boolean
    thumbnail: string
    permissions: Array<string>
}